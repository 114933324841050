export const searchMixin = {
  data() {
    return {
      customerSearch: "",
      employeeSearch: "",
      supplierSearch: "",
    };
  },
  watch: {
    customerSearch(newVal, oldVal) {
      if (newVal !== oldVal)
        this.$store.dispatch("customer/GetCustomer", {
          page: 1,
          limit: 10,
          where_like: newVal ? "id,name_1|" + newVal : newVal,
          order_by: "name_1|asc",
        });
    },
    employeeSearch(newVal, oldVal) {
      if (newVal !== oldVal)
        this.$store.dispatch("employee/GetEmployee", {
          page: 1,
          limit: 10,
          where_like: newVal ? "id,name|" + newVal : newVal,
          order_by: "name|asc",
        });
    },
    supplierSearch(newVal, oldVal) {
      if (newVal !== oldVal)
        this.$store.dispatch("supplier/GetSupplier", {
          page: 1,
          limit: 10,
          where_like: newVal ? "id,name_1|" + newVal : newVal,
          order_by: "name_1|asc",
        });
    },
  },
};
