<template>
  <div>
    <v-dialog
      v-model="dialog"
      @click:outside="$emit('update:dialog', false);"
      max-width="60%"
    >
      <Supplier class="p-0" :listSkeleton.sync="listSkeleton" @selectQuotationSupplier="selectQuotationSupplier" :type.sync="type" :sortByName="true">
        <template v-slot:closeIcon>
          <v-card-text class="pa-0 text-end">
            <v-icon class="mt-2 primary--text" @click="$emit('update:dialog', false);">mdi-close</v-icon>
          </v-card-text>
        </template>
      </Supplier>
    </v-dialog>
  </div>
</template>
<script>
import Supplier from '@/views/companyApp/supplier/Supplier';
export default {
  name: "SupplierDialog",
  props: ["dialog"],
  components: {
    Supplier
  },
  data() {
    return {
      listSkeleton: false,
      type: 'PurchaseOrderSupplier',
    }
  },
  watch: {
    dialog(val) {
      if(!val) {
        this.type = null;
      } else {
        this.type = 'PurchaseOrderSupplier';
        this.$store.dispatch('supplier/GetSupplier', {page: 1, limit: 10, order_by: 'name_1|asc'});
      }
    }
  },
  methods: {
    selectQuotationSupplier(item) {
      this.$emit('onSupplierChange', item);
      this.$emit('update:dialog', false);
    }
  },
}
</script>