<template>
  <v-select
    :readonly="isFieldReadable"
    v-model="localSelectedWarehouseModel"
    :items="warehouse2"
    item-text="description"
    item-value="id"
    return-object
    :rules="warehouseValid"
    :menu-props="{ bottom: true, offsetY: true }"
    :label="$t('delivery.field.warehouse')"
    @change="$emit('update:selectedWarehouseModel', selectedWarehouseModel)"
  />
</template>

<script>
import { mapGetters } from "vuex";
import { getAuxiliaryZtableValueByName } from "@/utils";

export default {
  name: "WarehouseInput",
  props: ["isFieldReadable", "selectedWarehouseModel"],
  data() {
    return {
      warehouse2: [],
      localSelectedWarehouseModel: this.selectedWarehouseModel,
    };
  },
  computed: {
    ...mapGetters({
      profileById: "profile/profileById",
    }),
    warehouseValid() {
      return [
        (v) =>
          !(
            this.profileById.warehouse_inventory_management &&
            this.profileById.warehouse_complusory
          ) ||
          !!v ||
          this.$t("formRules.warehouseRequired"),
      ];
    },
  },
  watch: {
    selectedWarehouseModel: {
      handler(newVal) {
        this.localSelectedWarehouseModel = newVal;
      },
      immediate: true,
    },
    localSelectedWarehouseModel(newVal) {
      this.$emit("update:selectedWarehouseModel", newVal);
    },
  },
  async mounted() {
    let warehouseNameValue = await getAuxiliaryZtableValueByName(20);
    if (Object.keys(warehouseNameValue).length > 0) {
      this.warehouse2 = warehouseNameValue.ztables.sort((a, b) =>
        a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
          ? 1
          : -1
      );
    }
  },
};
</script>
