import { mapGetters } from "vuex";

export const intersectionMixin = {
  computed: {
    ...mapGetters({
      customerList: "customer/customerList",
      supplierList: "supplier/supplierList",
      employeeList: 'employee/employeeList',
      contactList: "contact/contactList",
    }),
    hasNextPage() {
      if (this.customerList.data) {
        return this.customerList.data.length < this.customerList.meta.total;
      } else {
        return false;
      }
    },
    hasSupplierNextPage() {
      if (this.supplierList.data) {
        return this.supplierList.data.length < this.supplierList.meta.total;
      } else {
        return false;
      }
    },
    hasEmployeeNextPage() {
      if (this.employeeList.data) {
        return this.employeeList.data.length < this.employeeList.meta.total;
      } else {
        return false;
      }
    },
    hasContactNextPage() {
      if (this.contactList.data) {
        return this.contactList.data.length < this.contactList.meta.total;
      } else {
        return false;
      }
    },
  },
};
