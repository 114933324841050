import { mapGetters } from "vuex";

export const validationMixin = {
  data() {
    return {
      customerOrderNoRequired: false,
    };
  },
  computed: {
    ...mapGetters({
      profileById: "profile/profileById",
    }),
    titleValid() {
      return [
        (v) =>
          !this.profileById?.title || !!v || this.$t("formRules.titleRequired"),
        (v) => !v || !/^\s*$/.test(v) || this.$t("formRules.titleRequired"),
        (v) =>
          !v ||
          (v && v.length <= 250) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("quotation.field.title"),
            type: this.$t("common.less"),
            size: 250,
          }),
      ];
    },
    customerNameValid() {
      return [
        (v) => !!v || this.$t("formRules.customerRequired"),
        (v) => !v || !/^\s*$/.test(v) || this.$t("formRules.customerRequired"),
        (v) =>
          !v ||
          (v && v.length <= 255) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("quotation.field.customerName"),
            type: this.$t("common.less"),
            size: 255,
          }),
      ];
    },
    customerValid() {
      return [(v) => !!v || this.$t("formRules.customerRequired")];
    },
    supplierNameValid() {
      return [
        (v) => !!v || this.$t("formRules.supplierRequired"),
        (v) => !v || !/^\s*$/.test(v) || this.$t("formRules.supplierRequired"),
        (v) =>
          !v ||
          (v && v.length <= 250) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("purchaseOrder.supplier"),
            type: this.$t("common.less"),
            size: 250,
          }),
      ];
    },
    supplierValid() {
      return [(v) => !!v || this.$t("formRules.supplierRequired")];
    },
    dateValid() {
      return [
        (v) =>
          !v ||
          /(^0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(\d{4}$)/.test(v) ||
          this.$t("formRules.dateValid"),
      ];
    },
    dateValid2() {
      return [
        (v) =>
          !v ||
          /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.test(v) ||
          this.$t("formRules.dateValid2"),
      ];
    },
    dateValid3() {
      return [
        (v) => !!v || this.$t("formRules.dateRequired"),
        (v) =>
          !v ||
          /(^0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(\d{4}$)/.test(v) ||
          this.$t("formRules.dateValid"),
      ];
    },
    dateValid4() {
      return [
        (v) => !!v || this.$t("formRules.dateRequired"),
        (v) =>
          !v ||
          /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.test(v) ||
          this.$t("formRules.dateValid2"),
      ];
    },
    grandTotalRequestedValid() {
      return [
        (v) => {
          if (!v) return true;
          if (!/^\d{0,8}(\.\d{1,4})?$/.test(v)) {
            return this.$t("formRules.grandTotalRequestedInteger");
          }
          return true;
        },
      ];
    },
    quantityValid() {
      return [
        (v) =>
          !v ||
          /^\d{0,8}(\.\d{1,4})?$/.test(v) ||
          this.$t("formRules.quantityInteger"),
        (v) =>
          !v ||
          (v && String(v).length <= 12) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("quotation.field.quantity"),
            type: this.$t("common.less"),
            size: 12,
          }),
      ];
    },
    unitPriceValid() {
      return [
        (v) =>
          !v ||
          /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/.test(v) ||
          this.$t("formRules.spaceValidValue"),
        (v) => {
          const numericLength = String(v).replace(/[^0-9]/g, "").length;
          return (
            !v ||
            numericLength <= 12 ||
            this.$t("formRules.lengthValidMessage", {
              fieldName: this.$t("quotation.unitPrice"),
              type: this.$t("common.less"),
              size: 12,
            })
          );
        },
      ];
    },
    unitPriceValidInvoice() {
      return [
        (v) =>
          !v ||
          /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/.test(v) ||
          this.$t("formRules.spaceValidValue"),
        (v) =>
          !v ||
          (v && String(v).length <= 12) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("quotation.unitPrice"),
            type: this.$t("common.less"),
            size: 12,
          }),
        (v) =>
          !v || parseFloat(v) >= 0 || this.$t("formRules.negativeValueMessage"),
      ];
    },
    vatPrecentageValid() {
      return [
        (v) =>
          !v ||
          /^\d{0,8}(\.\d{1,4})?$/.test(v) ||
          this.$t("formRules.spaceValidValue"),
        (v) =>
          !v ||
          (v && String(v).length <= 5) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("purchaseOrder.vat"),
            type: this.$t("common.less"),
            size: 2,
          }),
        (v) =>
          !v ||
          parseFloat(v) <= 1000 ||
          this.$t("formRules.maxValueExceeded", {
            fieldName: this.$t("purchaseOrder.vat"),
            maxValue: 10,
          }),
      ];
    },
    customerOrderNoValid() {
      return [
        (v) =>
          !this.customerOrderNoRequired ||
          !!v ||
          this.$t("formRules.customerOrderNoRequired"),
        (v) =>
          !v ||
          !/^\s*$/.test(v) ||
          this.$t("formRules.customerOrderNoRequired"),
        (v) =>
          !v ||
          (v && v.length <= 15) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("creditInvoice.field.customerOrderNo"),
            type: this.$t("common.less"),
            size: 15,
          }),
      ];
    },
    lineDiscountValid() {
      return [
        (v) =>
          !v ||
          /^\d{0,8}(\.\d{1,4})?$/.test(v) ||
          this.$t("formRules.spaceValidValue"),
        (v) =>
          !v ||
          (v && String(v).length <= 11) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("purchaseOrder.discount"),
            type: this.$t("common.less"),
            size: 11,
          }),
      ];
    },
    carnoValid() {
      return [
        (v) =>
          !v ||
          !(v && String(v).length > 20) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("creditInvoice.field.carNo"),
            type: this.$t("common.less"),
            size: 20,
          }),
      ];
    },
    descriptionRequired() {
      return [
        (v) => !!v || this.$t("formRules.descriptionRequired"),
        (v) => !/^\s*$/.test(v) || this.$t("formRules.descriptionRequired"),
        (v) =>
          !v ||
          (v && v.length <= 250) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("bookkeepingBank.description"),
            type: this.$t("common.less"),
            size: 250,
          }),
      ];
    },
    accountNoRequired() {
      return [
        (v) => !!v || this.$t("formRules.accountNoRequired"),
        (v) => !v || /^[0-9]*$/.test(v) || this.$t("formRules.integerValue"),
        (v) =>
          !v ||
          (v && v.length <= 16) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("bookkeepingBank.accountNumber"),
            type: this.$t("common.less"),
            size: 16,
          }),
      ];
    },
    documentNumberRequired() {
      return [(v) => !!v || this.$t("formRules.documentNumberRequired")];
    },
  },
};
